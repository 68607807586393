import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Footer from "../components/Footer";
import Project from "../components/Project";
import Seo from "../components/Seo";

const Nandos = () => (
  <Layout>
    <Seo
      title={"Nando's"}
      description={
        "How Grenade is inspiring healthy attitudes to snacking in China. 15M to > 200M RMB increase in available market."
      }
    />
    <Project type="nandos" />
    <Footer />
  </Layout>
);

export default Nandos;

export const pageQuery = graphql`
  query NandosQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
